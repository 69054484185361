exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-bases-tsx": () => import("./../../../src/pages/company/bases.tsx" /* webpackChunkName: "component---src-pages-company-bases-tsx" */),
  "component---src-pages-company-chart-tsx": () => import("./../../../src/pages/company/chart.tsx" /* webpackChunkName: "component---src-pages-company-chart-tsx" */),
  "component---src-pages-company-history-tsx": () => import("./../../../src/pages/company/history.tsx" /* webpackChunkName: "component---src-pages-company-history-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-company-message-tsx": () => import("./../../../src/pages/company/message.tsx" /* webpackChunkName: "component---src-pages-company-message-tsx" */),
  "component---src-pages-company-philosophy-tsx": () => import("./../../../src/pages/company/philosophy.tsx" /* webpackChunkName: "component---src-pages-company-philosophy-tsx" */),
  "component---src-pages-company-strength-tsx": () => import("./../../../src/pages/company/strength.tsx" /* webpackChunkName: "component---src-pages-company-strength-tsx" */),
  "component---src-pages-company-summary-tsx": () => import("./../../../src/pages/company/summary.tsx" /* webpackChunkName: "component---src-pages-company-summary-tsx" */),
  "component---src-pages-contact-finish-tsx": () => import("./../../../src/pages/contact/finish.tsx" /* webpackChunkName: "component---src-pages-contact-finish-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-external-transmission-tsx": () => import("./../../../src/pages/external-transmission.tsx" /* webpackChunkName: "component---src-pages-external-transmission-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ir-disclaimer-tsx": () => import("./../../../src/pages/ir/disclaimer.tsx" /* webpackChunkName: "component---src-pages-ir-disclaimer-tsx" */),
  "component---src-pages-ir-disclosure-tsx": () => import("./../../../src/pages/ir/disclosure.tsx" /* webpackChunkName: "component---src-pages-ir-disclosure-tsx" */),
  "component---src-pages-ir-faq-tsx": () => import("./../../../src/pages/ir/faq.tsx" /* webpackChunkName: "component---src-pages-ir-faq-tsx" */),
  "component---src-pages-ir-index-tsx": () => import("./../../../src/pages/ir/index.tsx" /* webpackChunkName: "component---src-pages-ir-index-tsx" */),
  "component---src-pages-ir-individual-tsx": () => import("./../../../src/pages/ir/individual.tsx" /* webpackChunkName: "component---src-pages-ir-individual-tsx" */),
  "component---src-pages-ir-library-disclosure-tsx": () => import("./../../../src/pages/ir/library/disclosure.tsx" /* webpackChunkName: "component---src-pages-ir-library-disclosure-tsx" */),
  "component---src-pages-ir-library-index-tsx": () => import("./../../../src/pages/ir/library/index.tsx" /* webpackChunkName: "component---src-pages-ir-library-index-tsx" */),
  "component---src-pages-ir-library-others-tsx": () => import("./../../../src/pages/ir/library/others.tsx" /* webpackChunkName: "component---src-pages-ir-library-others-tsx" */),
  "component---src-pages-ir-library-presentations-tsx": () => import("./../../../src/pages/ir/library/presentations.tsx" /* webpackChunkName: "component---src-pages-ir-library-presentations-tsx" */),
  "component---src-pages-ir-library-reports-tsx": () => import("./../../../src/pages/ir/library/reports.tsx" /* webpackChunkName: "component---src-pages-ir-library-reports-tsx" */),
  "component---src-pages-ir-library-results-tsx": () => import("./../../../src/pages/ir/library/results.tsx" /* webpackChunkName: "component---src-pages-ir-library-results-tsx" */),
  "component---src-pages-ir-library-securities-tsx": () => import("./../../../src/pages/ir/library/securities.tsx" /* webpackChunkName: "component---src-pages-ir-library-securities-tsx" */),
  "component---src-pages-ir-news-tsx": () => import("./../../../src/pages/ir/news.tsx" /* webpackChunkName: "component---src-pages-ir-news-tsx" */),
  "component---src-pages-ir-notice-tsx": () => import("./../../../src/pages/ir/notice.tsx" /* webpackChunkName: "component---src-pages-ir-notice-tsx" */),
  "component---src-pages-ir-performance-cashflow-tsx": () => import("./../../../src/pages/ir/performance/cashflow.tsx" /* webpackChunkName: "component---src-pages-ir-performance-cashflow-tsx" */),
  "component---src-pages-ir-performance-finance-tsx": () => import("./../../../src/pages/ir/performance/finance.tsx" /* webpackChunkName: "component---src-pages-ir-performance-finance-tsx" */),
  "component---src-pages-ir-performance-highlight-tsx": () => import("./../../../src/pages/ir/performance/highlight.tsx" /* webpackChunkName: "component---src-pages-ir-performance-highlight-tsx" */),
  "component---src-pages-ir-performance-index-tsx": () => import("./../../../src/pages/ir/performance/index.tsx" /* webpackChunkName: "component---src-pages-ir-performance-index-tsx" */),
  "component---src-pages-ir-policy-disclosure-tsx": () => import("./../../../src/pages/ir/policy/disclosure.tsx" /* webpackChunkName: "component---src-pages-ir-policy-disclosure-tsx" */),
  "component---src-pages-ir-policy-governance-tsx": () => import("./../../../src/pages/ir/policy/governance.tsx" /* webpackChunkName: "component---src-pages-ir-policy-governance-tsx" */),
  "component---src-pages-ir-policy-index-tsx": () => import("./../../../src/pages/ir/policy/index.tsx" /* webpackChunkName: "component---src-pages-ir-policy-index-tsx" */),
  "component---src-pages-ir-policy-management-tsx": () => import("./../../../src/pages/ir/policy/management.tsx" /* webpackChunkName: "component---src-pages-ir-policy-management-tsx" */),
  "component---src-pages-ir-policy-philosophy-tsx": () => import("./../../../src/pages/ir/policy/philosophy.tsx" /* webpackChunkName: "component---src-pages-ir-policy-philosophy-tsx" */),
  "component---src-pages-ir-policy-strength-tsx": () => import("./../../../src/pages/ir/policy/strength.tsx" /* webpackChunkName: "component---src-pages-ir-policy-strength-tsx" */),
  "component---src-pages-ir-schedule-tsx": () => import("./../../../src/pages/ir/schedule.tsx" /* webpackChunkName: "component---src-pages-ir-schedule-tsx" */),
  "component---src-pages-ir-stock-index-tsx": () => import("./../../../src/pages/ir/stock/index.tsx" /* webpackChunkName: "component---src-pages-ir-stock-index-tsx" */),
  "component---src-pages-ir-stock-information-tsx": () => import("./../../../src/pages/ir/stock/information.tsx" /* webpackChunkName: "component---src-pages-ir-stock-information-tsx" */),
  "component---src-pages-ir-stock-procedure-tsx": () => import("./../../../src/pages/ir/stock/procedure.tsx" /* webpackChunkName: "component---src-pages-ir-stock-procedure-tsx" */),
  "component---src-pages-ir-stock-regulation-tsx": () => import("./../../../src/pages/ir/stock/regulation.tsx" /* webpackChunkName: "component---src-pages-ir-stock-regulation-tsx" */),
  "component---src-pages-ir-stock-shareholders-tsx": () => import("./../../../src/pages/ir/stock/shareholders.tsx" /* webpackChunkName: "component---src-pages-ir-stock-shareholders-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-privacy-after-202406-tsx": () => import("./../../../src/pages/privacy-after-202406.tsx" /* webpackChunkName: "component---src-pages-privacy-after-202406-tsx" */),
  "component---src-pages-privacy-before-202406-tsx": () => import("./../../../src/pages/privacy-before-202406.tsx" /* webpackChunkName: "component---src-pages-privacy-before-202406-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-service-financial-tsx": () => import("./../../../src/pages/service/financial.tsx" /* webpackChunkName: "component---src-pages-service-financial-tsx" */),
  "component---src-pages-service-hr-tsx": () => import("./../../../src/pages/service/hr.tsx" /* webpackChunkName: "component---src-pages-service-hr-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-service-manda-tsx": () => import("./../../../src/pages/service/manda.tsx" /* webpackChunkName: "component---src-pages-service-manda-tsx" */),
  "component---src-pages-service-outsourcing-tsx": () => import("./../../../src/pages/service/outsourcing.tsx" /* webpackChunkName: "component---src-pages-service-outsourcing-tsx" */),
  "component---src-pages-service-systemsolution-tsx": () => import("./../../../src/pages/service/systemsolution.tsx" /* webpackChunkName: "component---src-pages-service-systemsolution-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-sustainability-index-tsx": () => import("./../../../src/pages/sustainability/index.tsx" /* webpackChunkName: "component---src-pages-sustainability-index-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */)
}

